.chat-screen-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat-info-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin: 2rem 0 1rem 0;
}

.chat-info{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.chat-icons-container {
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 0 0 0.25rem 0;
}

.dots-icon {
    margin: 0 0 0 0;
    cursor: pointer;
} 

/* DELETE CHAT CSS */
.chat-item.selected {
    /* border: 2px solid #6246EA; */
    border-radius: 5px;
    background-color: var(--havyn-secondary);
    cursor: pointer;
}

.delete-icon {
    cursor: pointer;
    margin-left: 10px;
    stroke: var(--havyn-error);
}

.cancel-icon {
    margin: 0 0.5rem 0 0;
    color: var(--havyn-primary);
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.radial-dot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid var(--havyn-primary);
    margin: 0 0.5rem 0 0.5rem;
    max-width: 10%;
    cursor: pointer;
}

.radial-dot-selected {
    height: 20px;
    width: 20px;
    background-color: var(--havyn-primary);
    border-radius: 50%;
    display: inline-block;
    border: 1px solid var(--havyn-primary);
    margin: 0 0.5rem 0 0.5rem;
    max-width: 10%;
    cursor: pointer;
}
/* DELETE CHAT CSS */

.location-icon {
    fill: none;
    margin: 0 0 0 0;
    transform: scale(0.75);
} 

.chat-title {
    color: var(--havyn-primary);
    margin: 1rem 0 1rem 1rem;
    font-size: 2rem;
    margin: 0 0 0 0;
}

.chat-text {
    color: #ffffff;
    font-weight: 500;
    padding: .4rem;
    color: #ffffff;
    margin: 0 .5rem 0 .5rem;
}

.chat-subtext {
    font-weight: 400;
}

.chat-search-icon {
    width: 5%;
    scale: 1.5;
    color: var(--havyn-secondary);
    background-color: #F2F4F5;
    padding: 0.1155rem 0.1155rem 0.1155rem 0.3rem;
    border-radius: 5px 0 0 5px;
}

.chat-search {
    width: 95%;
    background-color: #F2F4F5;
    border: 0;
    color: var(--havyn-secondary);
    padding: 0.69rem;
    border-radius: 0 5px 5px 0;
    font-weight: 200;
}

.chat-search:focus {
    outline: none;
}

.chat-search::placeholder {
    color: var(--havyn-secondary);
}

/* Chat Nav CSS */

.chat-nav-container {
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.chat-nav-item {
    width: fit-content;
}

.chat-name-info {
    display: flex;
    align-items: center;
    width: auto;
}

.chat-actions {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0.5rem 1rem 0.5rem 0;
}

.chat-logs-container {
    background-color: white;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0 0 0;
    margin: 0 0 5rem 0;
    overflow-y: auto;
}

.chat-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 0.5rem 0;
    cursor: pointer;
}

.chat-img-container {
    width: fit-content;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 20%;
}

.chat-img-preview{
    width: 100%;
}

.chat-details-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 0 0 1rem;
    max-width: 65%;
}

.chat-details {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chat-meta-data{
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.chat-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    font-size: 1.25rem;
    cursor: pointer;
}

.chat-agent {
    margin: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.chat-preview-text {
    margin: 0;  
    font-size: 12px;
    color: var(--havyn-greyout);
    text-overflow: ellipsis;
}

.chat-missed-count{
    margin: 0.5rem 0 0 0;  
    font-size: 12px;
    text-align: center;
    background-color: var(--havyn-primary);
    border-radius: 10px;
    width: 15px;
    color: white;
}

.chat-time{
    margin: 0;  
    font-size: 12px;
    color: var(--havyn-greyout);
}

.chatwindow-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: var(--havyn-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(10px);
}

.chatwindow-back {
    height: 1rem;
    width: 1rem;
    cursor: pointer;
}

.chatwindow-calendar {
    height: 1.5rem;
    width: 1.5rem;
    stroke: var(--havyn-primary);
}

.chatwindow-img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}

.chatwindow-hd {
    /* width: in; */
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: end;
    padding: 16px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.chatwindow-userinfo {
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: auto;
}

.chatwindow-address {
    height: 1rem;
    font-size: .8rem;
    margin: 0
}

.chatwindow-txt-bar {
    height: 2rem;
    width: 100%;
    border-radius: 25px;
    border: 1px solid #ddd;
    margin: 0 1rem 0 0;
    text-indent: 1rem;
    font-size: 16px;
}

.chatwindow-txt-bar:focus {
    outline: none;
    color: black;
}

.chatwindow-txt-bar::placeholder {
    color: #707070;
    /* padding-left: 1rem; */
}

.chatwindow-btn-container {
    display: flex;
    align-items: center;
    padding: 0 1rem 2rem 1rem ;
}

.send-chat-btn {
    cursor: pointer;
    border-style: none;
    border-radius: 20px;
    /* padding: 0.5rem; */
    background-color: var(--havyn-primary);
    color: white;
    height: 2.2rem;
    display: flex;
    align-items: center;
}

.send-icon {
    transform: scale(1);
    stroke: white;
}



/* .buyer-txt-container {
    width: 100%;
    margin: 0 0 1rem 0;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
} */

#buyer {
    color: #686868;
    font-weight: 500;
    padding: .4rem;
    margin: 0 .5rem 0 .5rem;
    text-align: left;
}

/* .seller-txt-container {
    width: 100%;
    margin: 0 0 1rem 0;
    float: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
} */

#seller {
    color: #ffffff;
    font-weight: 500;
    padding: .4rem;
    color: #ffffff;
    margin: 0 .5rem 0 .5rem;
    text-align: left;
}

.buyer-setleft {
    display: inline-block;
    background-color: #F2F4F5;
    border-radius: 1rem;
    max-width: 70%;
    /* white-space: normal;
    word-break: break-word; */
}

.seller-setright {
    background-color: var(--havyn-primary);
    max-width: 70%;
    border-radius: 1rem;
    word-wrap: break-word;
    /* white-space: initial;
    overflow-wrap: break-word; */
}

.chat-cal-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: hsla(0, 0%, 0%, 0.582);
    backdrop-filter: blur(0.5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
}

.chat-cal-popup-cont {
    height: 55%;
    width: 100%;
    background-color: white;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    overflow: hidden;
}

.chat-cal-popup-slider {
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-cal-cancel {
    color: black;
    font-weight: 500;
    width: 85%;
    text-align: left;
    margin: 0;
    cursor: pointer;
}

.chat-cal-popup-time-sel {
    height: 65%;
    width: 100%;
    border-top: 1px solid rgb(226, 226, 226);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.chat-cal-time-sel-title {
    height: 3rem;
    width: 100%;
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: var(--havyn-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.chat-cal-time-selection {
    height: fit-content;    
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.invite-selection-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 1rem 0;
}

.invite-selection-title{
    margin: 0;
    color: black;
}

.chat-cal-date-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--havyn-secondary); /* Customize as needed */
    border: none;
    border-radius: 20px;
    padding: 10px;
    font-size: 16px;
    color: #4a4a4a; /* Customize as needed */
    width: 100%;
    max-width: 250px; /* Customize as needed */
    margin: 0 0 0 1rem;
    text-align: center;
    /* background: url('../../assets/icons/Iconly-Calendar.svg') no-repeat right 10px center;
    background-size: 20px 20px;  */
}

.chat-cal-time-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--havyn-secondary); /* Customize as needed */
    border: none;
    border-radius: 20px;
    padding: 10px;
    font-size: 16px;
    color: #4a4a4a; /* Customize as needed */
    width: 100%;
    max-width: 250px; /* Customize as needed */
    margin: 0 0 0 1rem;
    text-align: center;
    /* background: url('../../assets/icons/Iconly-Time-Circle.svg') no-repeat right 0px center;
    background-size: 20px 20px;  */
}

@media only screen and (max-width: 600px) {
    .chat-cal-date-input::-webkit-calendar-picker-indicator,
    .chat-cal-time-input::-webkit-calendar-picker-indicator {
        display: none; /* Hide the default calendar/time icon */
    }

    .chat-cal-date-input {
        /* background: url('../../assets/icons/Iconly-Calendar.svg') no-repeat right -15px center;
        background-size: 20px 20px; */
        border: solid 1px var(--havyn-secondary);
        min-width: 2rem;
    }

    .chat-cal-time-input {
        /* background: url('../../assets/icons/Iconly-Time-Circle.svg') no-repeat right -5px center;
        background-size: 20px 20px; */
        border: solid 1px var(--havyn-secondary);
        min-width: 2rem;
    }
}

.chat-cal-popup-foot {
    height: 20%;
    width: 100%;
    border-top: 1px solid rgb(226, 226, 226);
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-cal-inv-btn {
    height: 2.5rem;
    width: 85%;
    border: 0;
    background-color: var(--havyn-primary);
    border-radius: .7rem;
    color: white;
    margin-bottom: 2rem;
    cursor: pointer;
}


/* CHAT BUBUBLE RELATED CODE */
.chatwindow-msgs-container {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
}

.seller-txt-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.buyer-txt-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

/* .seller-setright p,
.buyer-setleft p {
    border-radius: 10px;
    padding: 10px;
    word-wrap: break-word;
} */

.seller-setright p {
    border-radius: 10px;
    padding: 10px;
    word-wrap: break-word;
    background-color: var(--havyn-primary);
    color: #fff;
    align-self: flex-end;
}

.buyer-setleft p {
    border-radius: 10px;
    padding: 10px;
    word-wrap: break-word;
    background-color: #f1f1f1;
    color: #000;
    align-self: flex-start;
}



.invite-card {
    padding: 0 0 0.75rem 0;
    background-color: white;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.1), 0 1px 0 rgba(0, 0, 0, 0.07);
    border-radius: 0 0 10px 10px;
}

.invite-card p {
    background-color: white;
    color: var(--havyn-primary);
    padding: 0.5rem 0 0 0;
}

.invite-card div {
    padding: 0.5rem 0 0 0;
}

.accept-btn {
    cursor: pointer;
    border-style: none;
    width: 40%;
    text-align: center;
    border-radius: 20px;
    padding: 0.5rem;
    background-color: var(--havyn-primary);
    color: white;
}

.decline-btn {
    cursor: pointer;
    border-style: none;
    width: 40%;
    text-align: center;
    border-radius: 20px;
    padding: 0.5rem;
    background-color: var(--havyn-error);
    color: white;
}

.chat-window-container::-webkit-scrollbar {
    width: 5px;
}

.chat-window-container::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: transparent;
}

.chat-window-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--havyn-greyout);
}

@media screen and (max-width: 768px) {
    .chat-window-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: scroll;
    }
    .chatwindow-bg {
        background-color: white;
    }
    /* .chatwindow-btn-container {
        position: absolute;
        bottom: 0;
    } */
}
@media screen and (min-width: 768px) {
    .chat-window-container {
        padding: 0 2rem;
        max-width: 992px;
        margin: auto;
        min-width: 650px;
        border-radius: 15px;
        box-shadow: 0 1px 3px var(--havyn-secondary), 0 1px 0 rgba(0, 0, 0, 0.07);
        background-color: white;
        height: 90svh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: scroll;
    }

    .chatwindow-hd {
        width: inherit;
        padding: 16px 0;
    }

    .chat-item {
        border-bottom: var(--havyn-secondary) 1px solid;
    }
    .chat-cal-popup-cont {
        max-width: 768px;
    }
}