.dashboard-main {
    width: 85%;
    margin: auto;
    width: 85%;
    margin: 0 auto 0 auto;
    height: 90svh;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 992px;
    border-radius: 15px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dashboard-main::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dashboard-main {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.dashboard-info-container {
    margin: 3rem 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashboard-image-container{
    height: 110px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.dashboard-img {
    width: 100%;
    border-radius: 50%;
    /* border: 2px solid var(--havyn-primary); */
}

.agent-icon {
    position: absolute;
    right: 10%;
    bottom: 0;
}

.dashboard-name {
    font-size: 1.5rem;
    color: var(--havyn-primary);
    margin: 0.5rem 0 0 0;
    font-weight: bold;
    cursor: pointer;
}

.dashboard-name-subtext {
    margin: 0 0 1rem 0;
}

.dashboard-title {
    font-size: 1.5rem;
    color: var(--havyn-primary);
}

.edit-buyer-btn {
    border-style: none;
    width: 100%;
    text-align: center;
    border-radius: 20px;
    padding: 0.5rem;
    background-color: var(--havyn-primary);
    color: white;
    cursor: pointer;
}

.edit-buyer-btn:hover {
    background-color: var(--havyn-secondary);
}

.edit-seller-btn {
    border-style: none;
    width: 50%;
    text-align: center;
    border-radius: 20px;
    padding: 0.5rem;
    background-color: var(--havyn-secondary);
    color: white;
    margin: 1.5rem 0 0 0;
}

.edit-seller-btn:hover {
    background-color: var(--havyn-secondary);
}

.dashboard-listing-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 2rem 0;
}

.listings-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

/* Listing Code */


.listing-list-view-container {
    position: relative;
    width: 100%;
    height: 85px;
    display: flex;
    align-items: center;
    margin: 0 0 1rem 0;
    cursor: pointer;
}

.listing-img-container {
    width: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0 0.5rem 0 0;
    aspect-ratio: 1;
}

.listing-img {
    width: 100%;
}

.listing-details-container {
    width: 100%;   
}

.property-approx-address{
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 0.85rem;
}

#approx-address-city {
    font-weight: 300;
}

.property-swipe-icons-container {
    display: flex;
    align-items: center;
}

.swipe-icon-details {
    margin: 0rem 0.75rem 0.25rem 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
}

.swipe-bed-icon {
    transform: scale(0.75);
}

.swipe-shower-icon {
    transform: scale(1.25);
    fill: #000000;
}

.swipe-sqft-icon {
    transform: scale(0.75);
    stroke-width: 1px;
}

.swipe-sqft-details {
    margin: 0 0 0 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
}

/* Buyer Profile Styles */

.buyer-approx-address {
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: black;
}

.buyer-list-view-container {
    position: relative;
    width: 100%;
    min-height: 55px;
    max-height: fit-content;
    display: flex;
    align-items: center;
    margin: 0 0 1rem 0;
    justify-content: space-between;
    border-bottom: 0.5px solid lightgray;
    cursor: pointer;
    padding: 1rem 0;
}

.buyer-details-container {
    width: fit-content;
    height: 100%;
}

.buyer-details-alt-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.buyer-details-budget-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buyer-details-price {
    font-size: 0.75rem;
    font-weight: 300;
    margin: 0;
}

#buyer-arrow {
    transform: scale(0.85)
}

/* Listings Styles */

.listing-details-price-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem 1rem 0 0;
}

.listing-details-price {
    font-size: 0.75rem;
    font-weight: 300;
    margin: 0;
    margin: 0 0.5rem 0 0;
}

.listing-arrow{
    fill: #D9D9D9;
    height: 20px;
    width: 18px;
    transform: scale(1);
}



/* This is the code for the listing portal  */

.listing-click {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.matches-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.matches-container {
    margin-top: 3rem;
    width: 85%;
}

.match-lsit-container{
    background-color: white;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0 0 0;
}

.matches-title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.match-container-title {
    font-size: 1.5rem;
    color: var(--havyn-primary);
}

.match-arrow-back {
    fill: #D9D9D9;
    height: 20px;
    width: 18px;
    transform: scale(1.5);
    transform: rotate(180deg);
    margin: 0 1rem 0 -0.5rem;
}

@media screen and (min-width: 768px) and (max-width: 1208px) {
    .dashboard-main {
        padding: 0 2rem;
        height: 100%;
        width: 100%;
        border-radius: 0 !important;
        margin: 0 !important;
        box-shadow: none;
        background-color: white;
    }
}

@media screen and (min-width: 768px) {
    .dashboard-main {
        padding: 0 2rem;
        margin: auto;
        box-shadow: 0 1px 3px var(--havyn-secondary), 0 1px 0 rgba(0, 0, 0, 0.07);
        background-color: white;
    }

    .listings-container {
        align-items: flex-start;
    }

    .edit-buyer-btn {
        max-width: 250px;
        padding: 0.75rem;
        border-radius: 25px;
        margin: 1rem 0 1rem 0;
    }
    
    .listing-details-price {
        font-size: 1rem;
    }

    .property-approx-address {
        font-size: 1.15rem;
    }

    .swipe-bed-icon {
        transform: scale(1);
    }

    .swipe-shower-icon {
        transform: scale(1.5);
        margin-right: 0.25rem;
    }

    .swipe-sqft-icon {
        transform: scale(1);
    }

    .swipe-icon-details {
        font-size: 1rem;
    }

    .swipe-sqft-details {
        font-size: 1rem;
    }
}