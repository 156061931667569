.loading-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D1D1E9;
}

.loading-logo {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: #FFFFFF;
}

