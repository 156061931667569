:root{
  --havyn-primary: #6246EA;
  --havyn-secondary: #D1D1E9;
  --havyn-greyout: #A8A8A8;
  --havyn-error: #e45858;
  --havyn-warning: #FFC107;
  --havyn-success: #4CAF50;
  --havyn-background: rgba(238, 238, 238, 0.541);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  height: 100svh;
  overflow-x: hidden;
  max-width: 100vw;
}

#bg-color-primary {
  background-color: var(--havyn-primary);
}

#stroke-color-primary {
  fill: var(--havyn-primary);
}

#color-secondary {
  background-color: var(--havyn-secondary);
}