.map-container {
    margin: 0.5rem 0 0 0;
    width: 100%;
}

.school-section-divider {
    background-color: #F4F4F4;
    height: 10rem;
    width: 1px;
}

.school-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.school-subsection {
    display: flex;
    flex-direction: column;
}

.school-list-container {
    width: 100%;
    height: fit-content;
    max-width: 250px;
}

.school-name {
    font-size: 1.25rem;
}

.school-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 60%; */
    height: 35px;
    color: var(--havyn-primary);
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    background-color: var(--havyn-secondary);
    border-radius: 30px;
    margin: 0.5rem 0 1rem 0;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
    border-style: none;
    padding: 1rem;
}

.school-button:hover {
    color: white;
    background-color: var(--havyn-primary);
}

.nearby-title {
    margin: 1rem 0 1rem 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    /* color: var(--havyn-primary); */
    color: #444444;
}

.info-icon {
    margin: 0 0 0 0.25rem;
    color: var(--havyn-greyout);
    font-size: 1rem;
    /* cursor: pointer; */
}
/* .info-icon:hover {
    color: lightgray;
} */

.info-modal {
    width: fit-content;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1110px) {
    .school-section {
        flex-direction: column;
    }
    .school-section-divider {
        background-color: #F4F4F4;
        height: 1px;
        width: 100%;
    }
}