.profile-page-main-container {
    width: 100%;
    height: 90svh;
    position: relative;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    max-width: 992px;
    background-color: white;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.profile-page-main-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.profile-page-main-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.profile-info-container {
    margin-top: 5rem;
    width: 85%;
    display: flex;
}

.profile-image-container {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.profile-img {
    width: 100%;
}

.profile-name-card-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.profile-name-card {
    font-size: 1.5rem;
    margin: 0 0 1rem 1rem;
    color: var(--havyn-primary); 
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.profile-account-label {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: left;
    width: 85%;
    margin: 3rem 0 0 0;
}

#first-account-header {
    margin: 0 1.5rem 0 0;
}

.profile-account-header {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 20px;
    border-bottom: #D1D1E9;
    border-width: 0 0 2.5px 0;
    border-style: solid;
    padding-bottom: 5px;
    margin: 0 0 0 0;
}

.profile-account-header-false {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    padding-bottom: 5px;
    color: #D9D9D9;
    opacity: 45%;
}

.profile-section-divider {
    background-color: #F4F4F4;
    height: 1px;
    width: 100%;
}


.profile-section {
    width: 85%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0 2rem 0;
}

.profile-selection-section-container {
    width: 100%;
}

.profile-favorites-container {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.profile-favorites-img {
    margin: 1rem 0.5rem 0 0;
    height: 100px;
    width: 100px;
    border-radius: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.profile-selection-container {
    width: 100%;
    padding: 1rem 0 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Arial, Helvetica, sans-serif;
}

.profile-selection-name {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.profile-selection-text {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: #000000;
}

.profile-arrow {
    fill: #D9D9D9;
    height: 20px;
    width: 18px;
    transform: scale(1.5);
    cursor: pointer;
    user-select: none;
}

.profile-arrow-collapsed {
    fill: #D9D9D9;
    height: 20px;
    width: 18px;
    transform: scale(1.5) rotate(90deg);
    cursor: pointer;
    user-select: none;
}

.profile-selections-divider {
    background-color: #F4F4F4;
    height: 1px;
    width: 100%;
}

.transaction-section{
    width: 100%;
    height: fit-content;
    /* background-color: #d9d9d91f; */
}

.transaction-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 1rem 0;
    /* border: 1px solid var(--havyn-secondary); */
    border-radius: 15px;
    padding: 1rem;
    background: #ffffff;
    box-shadow: 0 1px 3px var(--havyn-secondary), 0 1px 0 rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    /* background-color: var(--havyn-secondary); */
}

.transaction-title {
    margin: 0.25rem 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--havyn-primary);
}

.transaction-text {
    margin: 0.25rem 0;
}

.transaction-price {
    margin: 0.25rem 0;
    font-size: 1.25rem;
    font-weight: 600;
}

.transaction-top-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.agent-info-title {
    font-weight: 600;
    color: var(--havyn-primary);
}

.agent-info-text {
    font-weight: 400;
    color: #000000;
    padding: 0 0 0 0.5rem;
}

.logout-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logout-button {
    width: 50%;
    padding: 10px;
    background-color: var(--havyn-error);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
}

.logout-button:hover {
    background-color: #eb9292;
}

@media screen and (min-width: 768px) {
    .profile-page-main-container {
        width: 100%;
        margin: auto;
        border-radius: 15px;
        box-shadow: 0 1px 3px var(--havyn-secondary), 0 1px 0 rgba(0, 0, 0, 0.07);
        padding: 0 2rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1208px) {
    .profile-page-main-container{
      height: 100svh;
      margin: 0;
      border-radius: 0;
    }
  }
