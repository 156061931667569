/* #94ac92 cool green color */
:root {
    --gradient: linear-gradient(169deg, #356b8d 33.95%, #75a6c9 84.82%, #98bfdb 115.34%)
}

.login-main {
    height: 100vh;
    width: 100%;
    background-image: #FFFFFE;
    scrollbar-width: 0px;
    animation: bg-animation 5s infinite alternate;
    background-size: 200%;
}

.background {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: (169deg, #356b8d 33.95%, #75a6c9 84.82%, #98bfdb 115.34%) */
}

@keyframes bg-animation {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

.center-block {
    height: 420px;
    width: 320px;
    border-radius: 25px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    flex-direction: column;
    overflow: hidden;
}

.logo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: baseline;
    height: 74px;
    width: 185px;
    filter: #6246EA;
}

.login-havyn-logo {
    height: 70%;
    filter: #6246EA;
}

.login-logo-title {
    width: 180px;
    height: 75.868px;
    text-align: center;
    margin: 0;
    color: #6246EA;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 57px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    user-select: none;
}

.sign-on-container {
    width: 272px;
    height: 51px;
    flex-shrink: 0;
    border-radius: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    border-style: solid;
    border-color: #6246EA;
    border-width: .5px;
    fill: #0096FF;
}

.login-logo-base {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #F7F7F7;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.sign-on-text {
    color: #6246EA;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 0 0 0 20px;
    letter-spacing: .5px;
    user-select: none;
}

.trouble-signing-in {
    color: #6246EA;
    font-size: 12px;
    letter-spacing: 1.5px;
    font-family: Arial, Helvetica, sans-serif;
    user-select: none;
    cursor: pointer;
    background-color: transparent;
    border-style: none;
}