.welcome-main {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.welcome-open-animation {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.welcome-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    width: 80%;
    height: 150px;
}

.welcome-title {
    margin: 0 0 0 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    font-size: 45px;
    text-align: center;
    line-height: 40px;
    color: rgb(98, 70, 234);
}

.welcome-subtext {
    color: #c6c6d6;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
    font-size: 11px;
    text-align: center;
    margin: 15px 0 40px 0;
}

.welcome-image {
    width: 80%;
}

.buyer-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 45px;
    color: var(--havyn-primary);
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    background-color: var(--havyn-secondary);
    border-radius: 30px;
    margin: 50px 0 10px 0;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
    border-style: none
}

.buyer-button:hover {
    color: white;
    background-color: var(--havyn-primary);
}

.agent-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 45px;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    color: var(--havyn-primary);
    text-align: center;
    background-color: var(--havyn-secondary);
    border-radius: 30px;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
    border-style: none
}

.agent-button:hover {
    color: white;
    background-color: var(--havyn-primary);
}

.seller-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 45px;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    color: #6246EA;
    text-align: center;
    background-color: #D1D1E9;
    border-radius: 30px;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
    margin: 0 0 10px 0;
    border-style: none
}

.seller-button:hover {
    color: white;
    background-color: var(--havyn-primary);
}
