.payment-logo-container {
  width: 100%;
  margin: 0 auto;
  max-width: 540px;
  padding: 20px;
  display: flex;
  align-items: center;
}

.payment-form {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 1rem;
    background: #ffffff;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.1), 0 1px 0 rgba(0, 0, 0, 0.07);
    border-radius: 4px;
}
  
#payment-element {
    margin-bottom: 24px;
}
  
.payment-submit {
    background: #5469d4;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 20px;
    transition: all 0.2s ease;
    box-shadow: 0 4px 5.5px rgba(0, 0, 0, 0.07);
}
  
.payment-submit:hover {
    filter: contrast(115%);
}
  
.payment-submit:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
  
@media only screen and (max-width: 600px) {
    .payment-form {
        width: 90vw;
        padding: 10px;
    }
}
  

#payment-messages {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
    background-color: #0a253c;
    color: #00d924;
    padding: 20px;
    margin: 20px 0;
    border-radius: var(--radius);
    font-size: 0.7em;
}


.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .payment-logo-container {
    width: 80vw;
    min-width: initial;
  }
  .payment-form {
    width: 80vw;
    min-width: initial;
  }
}