.horizontal-slider {
    width: 100%;
    height: 25px;
    margin: 25px 0;
}

.example-thumb {
    height: 25px;
    width: 25px;
    background-color: var(--havyn-primary);
    border-radius: 50%;
    cursor: grab;
    box-shadow: 0 0 2px #aaa;
    bottom: 7px;
}

.example-thumb:active {
    cursor: grabbing;
}

.example-track {
    background: #ddd;
    height: 10px;
    border-radius: 5px;
}

.example-track.example-track-0 {
    background: #ddd;
}

.example-track.example-track-1 {
    background: var(--havyn-primary);
}

.example-track.example-track-2 {
    background: #ddd;
}
