.match-details-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: white; */
    z-index: 1001;
    overflow-y: auto;
    overflow-x: hidden;
}

.match-detials-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    font-size: 1.5rem;
}

.match-details-approve-icon {
    margin-left: 5px;
    vertical-align: middle;
    stroke: white;
    fill: var(--havyn-primary);
    /* transform: scale(0.85); */
    display: flex;
    align-items: center;
}

.match-image-container {
    height: 70px;
    width: 70px;
    position: relative;
    margin: 0 0.5rem 0.5rem 0;
}

.match-details-image-container {
    height: 70px;
    width: 70px;
    position: relative;
    margin: 1rem 0 0.5rem 0;
}

.match-details-modal-button-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 2rem 0 0 0;
}

.match-details-modal-button-container-selected {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 2rem 0 0 0;
    flex-direction: column;
}

.match-txt-bar {
    height: 2rem;
    width: 85%;
    border-radius: 25px;
    border: 1px solid #ddd;
    margin: 0 0 1rem 0;
    padding-left: 1rem;
    font-size: 16px;
}