/* styles.css */
.matches-container {
    margin-top: 0.5rem;
    max-height: auto; 
    overflow-y: auto; 
    width: 100%; /* Ensure it takes the full width */
}

.pre-approved-icon {
    margin-left: 5px;
    vertical-align: middle;
    stroke: white;
    fill: var(--havyn-primary);
    transform: scale(0.85);
}

/* Listing Nav CSS*/
.listing-nav-container {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 2rem 0 2rem;
    margin: 1.5rem 0 1rem 0;
}

.listing-nav-item {
    width: fit-content;
    margin: 0 2rem 0 0;
}

.listing-nav-header {
    font-family: Arial, Helvetica, sans-serif;
    border-bottom: var(--havyn-secondary);
    border-width: 0 0 2.5px 0;
    border-style: solid;
    margin: 0;
    color: var(--havyn-primary);
    cursor: pointer;
}

.listing-nav-header-false {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: var(--havyn-secondary);
    border-bottom: white;
    border-width: 0 0 2.5px 0;
    border-style: solid;
    margin: 0;
    cursor: pointer;
}

.listing-nav-text {
    font-size: 1.25rem;
    font-weight: 600;
}

.matches-section-container {
    height: auto;
    padding: 0 2rem 2rem 2rem;
}

.matches-list {
    height: auto;
    overflow-y: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.matches-list::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.matches-list {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.match-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative; /* Ensure the parent has position relative for proper absolute positioning */
    border-bottom: 0.5px solid lightgray;
    cursor: pointer;
}

.match-img {
    width: 100%;
    border-radius: 50%;
    cursor: pointer;
}

.match-details {
    flex-grow: 1;
}

.match-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    font-size: 1.25rem;
}

.match-location {
    margin: 0.25rem 0 0.25rem 0;
    font-weight: 400;
}

.match-budget {
    margin: 0 0 1rem 0;
    font-weight: 400;
}

.listing-details-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
    overflow-y: auto;
    overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.listing-details-modal::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.listing-details-modal {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.listing-details-modal-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 2rem 0;
}

.listing-details-arrow-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding: 2rem 2rem 0 2rem;
}

.edit-btn {
    stroke-width: 10px;
    cursor: pointer;
    stroke: var(--havyn-error)
}

.listing-details-content {
    height: auto;
    padding: 1rem 2rem 2rem 2rem;
}

.listing-details-image-carousel-container {
    width: 100%;
    max-width: 600px;
    margin: 0;
    overflow: hidden;
    border-radius: 15px;
}

.listing-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.listing-details-address {
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 2rem;
}

.listing-details-address-alt {
    margin: 0 0 1rem 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 300;
    font-size: 1.25rem;
}

.listing-details-container-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;    
}

.modal-header {
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    color: var(--havyn-primary);
}

.listing-details-icons-container {
    display: flex;
    align-items: center;
    margin: 0 0 0.25rem 0;
}

.description-title-container  {
    margin: 0;
}

.description-title {
    font-family: 'DM Sans', sans-serif;
    font-weight: 1000;
    font-size: 1.5rem;
    margin: 1rem 0 0.5rem 0;
    color: #6246EA;
}

.property-details-icon-container {
    margin: 0 1.25rem 0 0.5rem;
}

.details-icon-title {
    font-size: 0.55rem;
    font-weight: 300;
    margin: 0;
    color: #BEBBBB;
}

.details-icon-details {
    font-size: 0.75rem;
    margin: 0rem;
    font-weight: 800;
}

.swipe-bed-icon-pd {
    transform: scale(1.35);
    fill: black !important;
}

.swipe-shower-icon-pd {
    transform: scale(2);
    margin: 0 0.15rem 0 0;
}

.swipe-sqft-icon-pd {
    transform: scale(1.35);
}

.close-modal-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #f00;
    color: #fff;
    border: none;
    cursor: pointer;
}

.asking-price-input {
    width: fit-content;
    font-size: 1.25rem;
    font-weight: 400;
    border: none;
    text-align: center;
    color: var(--havyn-primary);
    border: 1px solid var(--havyn-primary);
    border-radius: 25px;
}
.asking-price-input:focus {
    font-size: 1.25rem;
    font-weight: bold;
    border: none;
    text-align: center;
    color: var(--havyn-primary);
    border: 1px solid var(--havyn-primary);
}

.listing-details-image-list {
    max-height: 300px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.image-actions {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
}

.cover-photo-indicator {
    background-color: #6246ea;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 0.8rem;
    margin-right: 10px;
}

.delete-icon {
    cursor: pointer;
    margin-left: 10px;
}

/* EDIT MODE CSS */
/* .uploaded-image-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
} */

/* .uploaded-image-wrapper img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
} */

.listing-details-content-edit {
    height: auto;
    padding: 1rem 2rem 2rem 2rem;
}

.listing-info-container-edit {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.uploaded-image-wrapper-edit {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    border-top: 0.5px solid var(--havyn-secondary);
    padding: 0.5rem 0 0 0;
}

.uploaded-image-edit {
    width: 65px;
    height: 65px;
    border-radius: 50px;
    margin: 0 0 0 0.5rem;
}

.listing-details-image-list-edit {
    max-height: 350px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0.5rem 0 0 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.listing-details-image-list-edit::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.listing-details-image-list-edit {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.description-title-container-edit {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.property-swipe-icons-container-edit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.property-details-icon-container-edit {
    margin: 0 0 1rem 0;
}

.listing-details-title-edit {
    margin: 1rem 0 1rem 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    color: var(--havyn-primary);
    white-space: nowrap
}

.listing-modal-input-edit {
    width: 100%;
    border-radius: .75rem;
    border: 1px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    letter-spacing: .1rem;
    font-size: 1rem;
    text-indent: 0.25rem;
    /* margin: 0 0 0 0.5rem; */
}

.listing-modal-input-extended-edit {
    width: 100%;
    border-radius: .75rem;
    border: 1px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    letter-spacing: .1rem;
    font-size: 1rem;
    text-indent: 0.25rem;
    height: 10rem;
}

.seller-modal-form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.delete-modal-button-container-edit {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 2rem 0 0 0;
}

.img-upload-container {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.listing-img-upload-btn {
    cursor: pointer;
    border-style: none;
    width: 40%;
    border-radius: 20px;
    padding: 0 0 0.25rem 0;
    background-color: var(--havyn-primary);
    color: white;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.listing-agent-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 1rem 0;
    /* border: 1px solid var(--havyn-secondary); */
    border-radius: 15px;
    /* padding: 1rem; */
    background: #e0e0e0;
    box-shadow: 0 1px 3px var(--havyn-secondary), 0 1px 0 rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    width: 100%;
}

.listing-agent-info-container {
    padding: 1rem;
}

@media screen and (min-width: 768px) {
    .listing-details-image-carousel-container {
        margin: auto;
    }
    .listing-details-modal {
        background-color: transparent;
    }
    .description-title-container  {
        display: flex;
        align-items: flex-end;
        margin: 0 0 1rem 0;
    }
    .listing-details-address-alt {
        margin: 0 0 0.25rem 0.5rem;
    }
    .swipe-bed-icon-pd {
        transform: scale(1.75);
        fill: black !important;
    }
    .details-icon-title {
        font-size: 0.75rem;
    }
    .swipe-shower-icon-pd {
        transform: scale(2.5);
        fill: black !important;
    }
    .details-icon-details {
        font-size: 1rem;
        margin: 0rem;
        font-weight: 800;
    }
    .swipe-sqft-icon-pd {
        transform: scale(1.5);
        fill: black !important;
    }
    .property-details-icon-container {
        margin: 0 1.5rem 0 0.75rem;
    }
}
