.agent-details-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 1rem 0;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 0 1px 3px var(--havyn-secondary), 0 1px 0 rgba(0, 0, 0, 0.07);
    border-radius: 5px;
}

.agent-details {
    padding: 0 1rem;
}

.buyer-modal-text-description {
    margin: 1rem 0 0 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    color: black;
}