.payment-content {
    width: 100%;
    height: 100svh;
    background-color: var(--havyn-background);
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment-container {
    padding: 2rem 2rem 0 2rem;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.header-title {
    text-align: center;
    flex-grow: 1;
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    color: var(--havyn-primary);
}

.premium-image {
    width: 100%;
    max-width: 200px;
    margin-bottom: 20px;
}

.premium-description {
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.premium-title {
    font-size: 20px;
    font-weight: bold;
}

.premium-price {
    font-size: 1.5rem;
}

.premium-subtitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.premium-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
}

.premium-price-divider {
    font-size: 2rem;
    color: var(--havyn-secondary);
    margin: 0 2rem;
}

.premium-info-stack {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#bold {
    font-weight: 700;
    font-size: 1.5rem;
}

.premium-text {
    font-size: 14px;
    color: #888;
    margin-bottom: 20px;
}

.premium-subtext {
    margin: 0;
}

.premium-highlights {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
}
.premium-divider {
    height: 1px;
    width: 100%;
    background-color: var(--havyn-secondary);
}

.option {
    width: 100%;
    max-width: 300px;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.option:hover {
    background-color: #e0e0e0;
}

.option-text {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    border: 2px solid white;
}

.option-text.selected {
    border: 2px solid var(--havyn-primary);
    border-radius: 10px;
}

.purchase-button {
    width: 100%;
    max-width: 300px;
    text-align: center;
    margin-top: 20px;
}

.purchase-button-text {
    background-color: var(--havyn-primary);
    width: 100%;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.purchase-button-text:hover {
    background-color: var(--havyn-primary);
}
