.setup-main {
    width: 100%;
    height: 100vh;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.buyersetup-dash-container {
    height: fit-content;
    width: 80%;
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}
.buyersetup-dash-true {
    height: 3px;
    width: 55px;
    background-color: var(--havyn-primary);
    border-radius: 10px;
    margin: 0 0.5rem 0 0.5rem;
}

.buyersetup-dash-false {
    height: 3px;
    width: 55px;
    background-color: var(--havyn-secondary);
    border-radius: 10px;
    margin: 0 0.5rem 0 0.5rem;
}

.setup-pages-main {
    height: 100%;
    width: 100%;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    padding-bottom: 6rem;
}

.setup-page1-title {
    height: 10rem;
    width: 80%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    color: var(--havyn-primary);
    font-size: 2.5rem;
    text-align: center;
    line-height: 2.75rem;
    margin: 0;
}

.setup-page1-form {
    height: 2.5rem;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10rem;
}

.setup-areaofintrest {
    height: 100%;
    width: 100%;
    border-radius: .75rem;
    border: 2px solid var(--havyn-secondary);
    color: var(--havyn-secondary);
    letter-spacing: .1rem;
    font-size: .75rem;
}

.setup-areaofintrest:focus {
    outline:none;
    padding-left: .5rem;
}

.setup-areaofintrest::placeholder {
    color: var(--havyn-secondary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: .75rem;
    padding-left: .5rem;
    letter-spacing: .1rem;
}

.setup-page2-title {
    height: 7rem;
    width: 80%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    color: var(--havyn-primary);
    font-size: 2.2rem;
    padding-bottom: .75rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2.25rem;
}

.setup-page2-btns-container {
    height: 3rem;
    width: 90%;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
}

.setup-page2-buy-btn {
    height: 100%;
    width: 40%;
    border: 1px solid var(--havyn-primary);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--havyn-primary);
}

.setup-page2-buy-btn:hover {
    color: #FFFFFF;
    background-color: var(--havyn-primary);
}

.setup-page2-rent-btn {
    height: 100%;
    width: 40%;
    border: 1px solid var(--havyn-primary);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--havyn-primary);
}

.setup-page2-rent-btn:hover {
    color: #FFFFFF;
    background-color: var(--havyn-primary);
}


.setup-page3-title {
    height: 7rem;
    width: 80%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    color: var(--havyn-primary);
    font-size: 1.55rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2.25rem;
    margin: 0;
}

.setup-page3-selections-container {
    height: 40%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.setup-page3-selections-row {
    height: 45%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 1rem;
}

.setup-page3-boxes {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    height: 8rem;
    width: 45%;
    border: 1px solid var(--havyn-primary);
    border-radius: 1rem;
    cursor: pointer;
}

.setup-page3-boxes.active {
    background-color: var(--havyn-secondary);
}

.setup-page3-boxes-title {
    color: var(--havyn-primary);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 1rem;
    margin: .5rem 0 .75rem 0;
}

/* .signle-family-icon:hover {
    fill: var(--havyn-secondary)
} */

.setup-page3-next-btn {
    height: 2.5rem;
    width: 8rem;
    background-color: var(--havyn-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 1rem;
    margin-top: 8rem;
    margin-bottom: 4rem;
}

.setup-page4-title {
    height: 7rem;
    width: 85%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    color: var(--havyn-primary);
    font-size: 2.2rem;
    padding-top: 3rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2rem;
}

.setup-page4-criteria-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.setup-page4-criteria-title {
    height: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    color: var(--havyn-primary);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0 0 1rem 0;
}

.setup-page4-criteria-selections {
    height: 3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 2rem;
}

.setup-page4-any {
    height: 2rem;
    width: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--havyn-secondary);
    border-radius: 1rem;
    color: var(--havyn-secondary);
}

.setup-page4-any-selected {
    background-color: var(--havyn-secondary);
    color: white;
}

.setup-page4-number {
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--havyn-secondary);
    border-radius: 1rem;
    color: var(--havyn-secondary);
}

.setup-page4-number-selected {
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--havyn-primary);
    border-radius: 1rem;
    background-color: var(--havyn-primary);
    color: white;
}

.setup-page4-sqft-selections-container {
    height: 5rem;
    width: 100%;
}

.setup-page4-min-max-label {
    height: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--havyn-secondary);
    margin-top: .2rem;
}

.setup-page4-min-max-btn-container {
    height: 3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: .2rem;
}

.setup-page4-btn {
    height: 2.5rem;
    width: 9rem;
    border: 1px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    text-align: center;
}

.setup-page4-btn::placeholder {
    color: var(--havyn-secondary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
}

.setup-page4-btn:focus {
    outline:none;
    border: 1px solid var(--havyn-primary);
}

.setup-page4-btn-highlight {
    height: 2.5rem;
    width: 9rem;
    border: 1px solid var(--havyn-primary);
    color: var(--havyn-primary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    text-align: center;
}

.setup-page4-btn-highlight::placeholder {
    color: var(--havyn-primary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
}

.setup-page4-btn-highlight:focus {
    outline:none;
    border: 1px solid var(--havyn-primary);
}

.setup-page4-next-btn {
    height: 2.5rem;
    width: 8rem;
    background-color: var(--havyn-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 1rem;
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.setup-page5-title {
    height: 7rem;
    width: 90%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    color: var(--havyn-primary);
    font-size: 2.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2rem;
    margin: 0 0 2rem 0;
}

.setup-page5-selections-container {
    height: 5rem;
    width: 80%;
    margin-top: 1.5rem;
}

.setup-page5-min-max-btn-container {
    height: 3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: .2rem;
}

.setup-page5-next-btn {
    height: 2.5rem;
    width: 8rem;
    background-color: var(--havyn-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 1rem;
}

/* styles.css */
.setup-page-pre-approved-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.setup-pre-approved-btn {
    background-color: var(--havyn-secondary);
    color: white;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 50px;
    margin: 0 0.5rem;
    cursor: pointer;
}

.setup-pre-approved-btn.active {
    background-color: var(--havyn-primary);
}

.setup-dropdown-selected-count {
    color: var(--havyn-primary);
    display: flex;
    min-width: 5rem;
    margin: 0;
}

.setup-toggle-button {
    cursor: pointer;
    height: 100%;
    border: 1px solid var(--havyn-primary);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.75rem 2rem 0.75rem 2rem;
    color: var(--havyn-primary);
    background-color: #ff000000;
    height: 2.5rem;
    width: fit-content;
}

.setup-toggle-button.active {
    color: #FFFFFF;
    background-color: var(--havyn-primary);
}

.setup-dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 20rem;
}

.setup-dropdown-item {
    display: flex;
    align-items: flex-start;
    width: 100%;
    background-color: var(--havyn-secondary);
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    font-size: 1.25rem;
    padding: 0.5rem;
    border-bottom: solid 1px black;
}

.setup-dropdown-item.active {
    color: white;
    background-color: var(--havyn-primary);
    border-bottom: solid 1px var(--havyn-secondary);
}

/*       ScrollBar CSS        */

.scrollbar {
    height: 300px;
    width: 50%;
    overflow: auto;
    padding: 0 10px;
}

.setup-dropdown-container::-webkit-scrollbar {
    width: 5px;
}

.setup-dropdown-container::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: transparent;
}

.setup-dropdown-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--havyn-greyout);
}

.setup-main::-webkit-scrollbar {
    width: 5px;
}

.setup-main::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: transparent;
}

.setup-main::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--havyn-greyout);
}


@media screen and (min-width: 768px) {
    .setup-main {
        height: 90svh;
        margin: auto;
        width: 100%;
        border-radius: 15px;
        box-shadow: 0 1px 3px var(--havyn-secondary), 0 1px 0 rgba(0, 0, 0, 0.07);
        background-color: white;
        max-width: 992px;
    }
}

@media screen and (max-width: 768px) {
    .setup-page6-title {
        font-size: 1.5rem;
    }
  }
