.fullscreen-gallery-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow: hidden;
}

.fullscreen-gallery-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullscreen-gallery-header {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10000;
    display: flex;
    align-items: center;
}

.fullscreen-gallery-back {
    cursor: pointer;
    /* fill: #fff; */
    width: 30px;
    height: 30px;
}

.fullscreen-gallery-content {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullscreen-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 100%;
}

.fullscreen-image {
    max-width: 90%;
    max-height: 100%;
    object-fit: contain;
}

.fade-in {
    animation: fadeIn 0.5s;
}

.fade-out {
    animation: fadeOut 0.5s;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

.carousel .slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 768px) and (max-width: 1250px) {
    .fullscreen-gallery-content {
        width: 90%
    }
}

@media screen and (max-width: 768px) {
    .fullscreen-image-wrapper {
        width: 100%;
    }

    .fullscreen-gallery-content {
        width: 100%
    }
}
