/* modalStyles.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.buyer-modal-content {
    max-width: 500px;
    width: auto;
    margin: 2rem 2rem 5rem 2rem;
}

.modal-section-header {
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: var(--havyn-primary);
}

.buyer-modal-dropdown-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Arial, Helvetica, sans-serif;
}

.buyer-modal-bubble-container {
    padding: 0.5rem 0 0 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    color: black;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.term-bubble {
    display: inline-block;
    background-color: #e0e0e0;
    color: #333;
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 14px;
}

.buyer-modal-boxes {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    height: 6rem;
    width: 40%;
    border: 1px solid var(--havyn-primary);
    border-radius: 1rem;
    cursor: pointer;
    margin: 0 1rem 1rem 0;
    padding: 1rem 0 0 0;
    background-color: var(--havyn-secondary);
}

.buyer-modal-title {
    margin: 1rem 0 1rem 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    /* color: var(--havyn-primary); */
    color: #444444;
    /* white-space: nowrap */
}

.buyer-modal-text {
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    color: black;
}

.buyer-modal-form-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.buyer-modal-form-group-extended {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.buyer-modal-input {
    width: 100%;
    border-radius: .75rem;
    border: 1px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    letter-spacing: .1rem;
    font-size: 1rem;
    padding: 0.25rem;
    margin: 0 0 0 0.5rem;
}

.buyer-modal-input-extended {
    width: 97%;
    border-radius: .75rem;
    border: 1px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    letter-spacing: .1rem;
    font-size: 1rem;
    padding: 0.25rem;
    margin: 0 0 1rem 0;
}

.buyer-modal-input:focus {
    outline:none;
    border: 1px solid var(--havyn-primary);
}

.buyer-modal-input::placeholder {
    color: var(--havyn-secondary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: .75rem;
    padding-left: .5rem;
    letter-spacing: .1rem;
}

.buyer-modal-save-btn {
    cursor: pointer;
    border-style: none;
    width: 40%;
    text-align: center;
    border-radius: 20px;
    padding: 0.5rem;
    background-color: var(--havyn-primary);
    color: white;
    height: 2rem;
}

.buyer-modal-cancel-btn {
    cursor: pointer;
    border-style: none;
    width: 40%;
    text-align: center;
    border-radius: 20px;
    padding: 0.5rem;
    background-color: var(--havyn-secondary);
    color: white;
}

/* Delete Modal CSS */
.delete-confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.delete-confirmation-content {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    max-width: 500px;
    width: 70%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.delete-modal-button-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.delete-confirm-btn {
    cursor: pointer;
    border-style: none;
    width: 40%;
    text-align: center;
    border-radius: 20px;
    padding: 0.5rem;
    background-color: var(--havyn-error);
    color: white;
}

.delete-cancel-btn {
    cursor: pointer;
    border-style: none;
    width: 40%;
    text-align: center;
    border-radius: 20px;
    padding: 0.5rem;
    background-color: var(--havyn-secondary);
    color: white;
}

/* styles.css */
.setup-page-pre-approved-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.setup-pre-approved-btn {
    background-color: var(--havyn-secondary);
    color: white;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 50px;
    margin: 0 0.5rem;
    cursor: pointer;
}

.setup-pre-approved-btn.active {
    background-color: var(--havyn-primary);
}

.buyer-modal-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.buyer-modal-input-description {
    width: 100%;
    border-radius: .75rem;
    border: 1px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    letter-spacing: .1rem;
    font-size: 1rem;
    padding: 0.25rem;
    height: 5rem;
}
