.agent-setup-container {
    padding: 2rem 2rem 0 2rem;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.agent-setup-disclaimer {
    font-size: 12px;
    margin: 0;
}

.agent-setup-form-container {
    width: 100%;
    height: fit-content;
}

.agent-setup-form {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.agent-input-title {
    color: var(--havyn-primary);
    margin: 0.25rem 0 0.25rem 0;
    font-weight: 600;
    font-size: 1rem;
}

.agent-input {
    height: 2rem;
    width: 100%;
    border-radius: .75rem;
    border: 2px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    letter-spacing: .1rem;
    font-size: 1rem;
    margin: 0.5rem 0 1rem 0;
    text-indent: 0.5rem;
}

.agent-input:focus {
    outline:none;
    border: 2px solid var(--havyn-primary);
}

.agent-input::placeholder {
    color: var(--havyn-secondary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: .75rem;
}

.verify-button-text {
    background-color: var(--havyn-primary);
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}