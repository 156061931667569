.setup-page-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 0 2rem 0;
}

.setup-page-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 70%;
}

.setup-page-form-listing-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
}

.setup-page-form-property-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 85%;
}

.setup-page-criteria-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.setup-page-form-budget {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.setup-page-form-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
}

.setup-input-description-title {
    color: var(--havyn-primary);
    margin: 0.25rem 0 1rem 0;
}

.setup-input-title {
    color: var(--havyn-primary);
    margin: 0.25rem 0 0.25rem 0;
    font-size: 16px;
}

.setup-input {
    height: 100%;
    width: 100%;
    border-radius: .75rem;
    border: 2px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    letter-spacing: .1rem;
    font-size: 1rem;
    padding: 0.5rem;
    margin: 0.5rem 0 1rem 0;
}

.setup-input:focus {
    outline:none;
    border: 2px solid var(--havyn-primary);
}

.setup-input::placeholder {
    color: var(--havyn-secondary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: .75rem;
    padding-left: .5rem;
    letter-spacing: .1rem;
}

.setup-input-number {
    height: 100%;
    width: 100%;
    border-radius: .75rem;
    border: 2px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    letter-spacing: .1rem;
    font-size: 1rem;
    padding: 0.5rem;
    margin: 0.5rem 0 1rem 0;
}

.setup-input-number:focus {
    outline:none;
    border: 2px solid var(--havyn-primary);
}

.setup-input-number::placeholder {
    color: var(--havyn-secondary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: .75rem;
    padding-left: .5rem;
    letter-spacing: .1rem;
}

input.setup-input-number::-webkit-outer-spin-button,
input.setup-input-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number].setup-input-number{
    appearance: textfield;
}

input.setup-page4-btn::-webkit-outer-spin-button,
input.setup-page4-btn::-webkit-inner-spin-button,
input.setup-page4-btn-highlight::-webkit-outer-spin-button,
input.setup-page4-btn-highlight::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number].setup-page4-btn{
    appearance: textfield;
}


input[type=number].setup-page4-btn-highlight{
    appearance: textfield;
}



.setup-input-extended {
    width: 80%;
    border-radius: .75rem;
    border: 2px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    letter-spacing: .1rem;
    font-size: 16px;
    padding: 1rem 0 4rem 1rem;
    margin: 0.5rem 0 1rem 0;
}

.setup-input-extended:focus {
    outline:none;
    border: 2px solid var(--havyn-primary);
}

.setup-input-extended::placeholder {
    color: var(--havyn-secondary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: .75rem;
    padding-left: .5rem;
    letter-spacing: .1rem;
}

.setup-input-extended-description {
    width: 100%;
    border-radius: .75rem;
    border: 2px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    letter-spacing: .1rem;
    font-size: 16px;
    height: 20rem;
    margin: 0.5rem 0 1rem 0;
    text-indent: 0.5rem;
}

.setup-input-extended-description:focus {
    outline:none;
    border: 2px solid var(--havyn-primary);
}

.setup-input-extended-description::placeholder {
    color: var(--havyn-secondary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: .75rem;
    text-indent: 0.5rem;
    letter-spacing: .1rem;
}

.setup-page-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 0 0;
}

.setup-page-button-container-pref {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 1rem 0;
}

.setup-page-status-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0 0 2rem 0;
}

.setup-status-btn {
    cursor: pointer;
    height: 100%;
    border: 1px solid var(--havyn-primary);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 700;
    padding: 0.75rem 2rem 0.75rem 2rem;
    color: var(--havyn-primary);
    background-color: #ff000000;
}

.setup-status-btn.active {
    color: #FFFFFF;
    background-color: var(--havyn-primary);
}


.setup-page-next-btn {
    height: 2.5rem;
    width: 8rem;
    background-color: var(--havyn-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 1rem;
    border-style: none;
    cursor: pointer;
}

.setup-page-back-btn {
    height: 2.5rem;
    width: 8rem;
    background-color: var(--havyn-secondary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 1rem;
    border-style: none;
    cursor: pointer;
    margin: 0 2rem 0 0;
}

.setup-page-property-select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.setup-page-criteria-number {
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--havyn-secondary);
    border-radius: 1rem;
    color: var(--havyn-secondary);
    cursor: pointer;
}

.setup-page-criteria-number.any {
    height: 2.5rem; /* Adjust to make it larger */
    width: 5rem;
    border-radius: 25px; /* Adjust to make it larger */
    cursor: pointer;
}

.setup-page-criteria-number.active {
    background-color: var(--havyn-primary);
    border: 1px solid var(--havyn-primary);
    color: white; /* Optional: change text color for active state */
}

.setup-input-sqft {
    width: 30%;
    border-radius: .75rem;
    border: 1px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    letter-spacing: .1rem;
    font-size: 16px;
    padding: 0.5rem;
    margin: 0.5rem 0 1rem 0;
    border-radius: 25px;
}

.setup-input-sqft:focus {
    outline:none;
    padding-left: .5rem;
    border: 1px solid var(--havyn-primary);
}

.setup-input-sqft::placeholder {
    color: var(--havyn-secondary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    padding-left: .5rem;
    letter-spacing: .1rem;
}

.budget-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0 1rem 0;
}

.setup-page-price-btn {
    height: 2.5rem;
    width: 9rem;
    border: 1px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    text-align: center;
    margin: 0.25rem 0 0.5rem 0;
}

.setup-page-price-btn::placeholder {
    color: var(--havyn-secondary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: .75rem;
}

.setup-page-price-btn:focus {
    outline:none;
    border: 1px solid var(--havyn-primary);
}


/* SetupPage6 CSS */
.upload-setup-page-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.upload-setup-page-content {
    width: 80%;
    max-width: 600px;
}

.setup-page6-title {
    text-align: center;
    margin: 0 0 0.5rem 0;
    color: var(--havyn-primary);
    font-weight: 700;
}

.upload-btn-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-icon {
    transform: scale(1.5);
    margin: 0 1rem 0 0;
}

input[type="file"] {
    display: none;
}
.upload-file-btn {
    height: 2rem;
    width: 6rem;
    background-color: var(--havyn-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1rem;
    border-radius: 50px;
    border-style: none;
    cursor: pointer;
    margin: 0 1rem 0 0;
}

.upload-setup-page-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.uploaded-images-preview {
    width: 100%;
    margin-top: 20px;
    position: relative;
}

.uploaded-image-wrapper {
    position: relative;
    width: 100%;
}

.uploaded-image {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.cover-button {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.cover-button.selected {
    background-color: rgba(0, 128, 0, 0.7);
}

.setup-page-button-container {
    margin-top: 20px;
}

.submit-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 2rem 0;
}

.submit-btn {
    height: 2.5rem;
    width: 8rem;
    background-color: var(--havyn-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 1rem;
    border-style: none;
    cursor: pointer;
}

.setup-page-single-input-container {
    height: 4rem;
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: left;
    color: var(--havyn-primary);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0 0 1rem 0;
}

.setup-page-single-input-title {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: left;
    color: var(--havyn-primary);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0 1rem 0 0;
}

.setup-page-single-input {
    border-radius: 50px;
}

#fit-content {
    width: fit-content !important;
}

.setup-page-single-select {
    height: 2.75rem;
    /* width: 9rem; */
    border: 1px solid var(--havyn-secondary);
    color: var(--havyn-primary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    text-align: center;
    padding: 0 1rem;
}

.setup-example-text {
    color: var(--havyn-secondary);
    font-family: Arial, Helvetica, sans-serif;
    font-size: .75rem;
    padding-left: .5rem;
    letter-spacing: .1rem;
}

/* Carousel CSS Override */
.carousel .thumbs-wrapper {
    margin: 0 !important;
}

@media screen and (max-width: 768px) {
    .setup-page-single-input-container {
        height: fit-content;
        flex-wrap: wrap;
    }
    .setup-page-single-input-title {
        width: 100%;
    }
}
