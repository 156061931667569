.navhub-container {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #FFF;
    display: flex;
    justify-content: space-around;
    /* padding: 1rem 0 2rem 0; */
    border-radius: 0 0 0 0;
    box-shadow: -9px 11px 14px rgba(0, 0, 0, 0.25);
    height: 10svh;
    align-items: center;
}

.navhub-container-background {
    height: 100%;
    width: 100%;
    background-color: black;
}

.navhub-home-icon {
    width: 25px;
    fill: var(--havyn-secondary);
}

.navhub-home-icon-filled {
    width: 25px;
    fill: var(--havyn-primary);
}

.navhub-chat-icon {
    width: 25px;
    fill: none;
}

.navhub-chat-icon-filled {
    width: 25px;
    fill: #6246EA;
    stroke: #6246EA;
}

.navhub-profile-icon {
    width: 20px;
    fill: #D1D1E9;
}

.navhub-profile-icon-filled {
    width: 20px;
    fill: #6246EA;
}

@media screen and (min-width: 768px) {
    .navhub-container {
        position: unset;
        box-shadow: none;
        height: 100svh;
        width: 150px;
        flex-direction: column;
        justify-content: flex-start;
        border-right: var(--havyn-secondary) 1px solid;
    }

    .navhub-icon {
        padding: 2rem 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: var(--havyn-secondary) 1px solid;
        cursor: pointer;
    }

    .navhub-icon:hover {
        background-color: var(--havyn-primary);
    }
}