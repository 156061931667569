.main {
  position: relative;
  height: 100svh;
}

.main-swipe {
  width: 100%;
  height: 100svh;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-section {
  display: flex;
  flex-direction: row;
  background-color: var(--havyn-background);
}

.chat-screen {
  padding: 0 2rem;
  width: 100%;
  height: 90svh;
  border-radius: 15px;
  max-width: 992px;
  margin: auto;
  background-color: white;
}

.main-content {
  width: 85%;
  padding: 0 2rem;
  height: 90svh;
  max-width: 992px;
  margin: auto;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 1px 3px var(--havyn-secondary), 0 1px 0 rgba(0, 0, 0, 0.07);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chat-screen::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-screen {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.havyn-logo {
  width: 100%;
}

.report-icon {
  opacity: 0;
}

.match-section {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 !important;
  width: 100%;
  height: 100svh;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.seller-setup-main-section {
  display: flex;
  flex-direction: row;
  background-color: var(--havyn-background);
}

.main-section::-webkit-scrollbar {
  width: 5px;
}

.main-section::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
}

.main-section::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--havyn-greyout);
}

.main-listing-content::-webkit-scrollbar {
  width: 5px;
}

.main-listing-content::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
}

.main-listing-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--havyn-greyout);
}

@media screen and (min-width: 768px) {
  .main-section {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 !important;
    width: 100%;
    height: 100svh;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .seller-setup-main-section {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 !important;
    width: 100%;
    height: 100svh;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .match-section {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 !important;
    width: 100%;
    height: 100svh;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    overflow-y: scroll;
  }

  .match-listing-content {
    width: 85%;
    padding: 0 2rem;
    height: 90svh;
    max-width: 992px;
    margin: auto;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 1px 3px var(--havyn-secondary), 0 1px 0 rgba(0, 0, 0, 0.07);
  }

  .chat-screen {
    margin: auto;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 1px 3px var(--havyn-secondary), 0 1px 0 rgba(0, 0, 0, 0.07);
    background-color: white;
  }
  .main-listing-content {
    width: 85%;
    padding: 0 2rem;
    height: 90svh;
    max-width: 992px;
    margin: auto;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 1px 3px var(--havyn-secondary), 0 1px 0 rgba(0, 0, 0, 0.07);
    overflow-y: scroll;
  }
}

@media screen and (min-width: 768px) and (max-width: 1208px) {
  .chat-screen {
    height: 100svh;
    margin: 0;
    border-radius: 0;
  }
}

@media screen and (max-width: 768px) {
  .main-content {
    height: 100svh;   
    width: 100%;
  }

  .main-listing-content {
    width: 100%;
    padding: 0;
  }
  .main-section {
    background-color: white;
  }
  .match-listing-content {
    width: 100%;
    padding: 0;
  }
  .match-section {
    background-color: white;
  }
  .seller-setup-main-section {
    background-color: white;
  }
}

/* Page Animation CSS */

.left {
  animation: left 0.5s;
}

@keyframes left {
  from {
    transform: translateX(100vw)
  }
  to {
    transform: translateX(0)
  }
}

.leftClose {
  animation: leftClose 0.5s;
}

@keyframes leftClose {
  from {
    transform: translateX(0)
  }
  to {
    transform: translateX(100vw)
  }
}

.right {
  animation: right 0.5s;
}

@keyframes right {
  from {
    transform: translateX(-100vw)
  }
  to {
    transform: translateX(0)
  }
}

.rightClose {
  animation: rightClose 0.5s;
}

@keyframes rightClose {
  from {
    transform: translateX(0)
  }
  to {
    transform: translateX(-100vw)
  }
}